import React, { useState, useContext } from "react";

const AppContext = React.createContext();
const appData = {
  rangyu: {
    siteName: "PPUU",
    siteIcon: "PU",
    copyright: "ⓒ PPUU",
  },
};

export const AppProvider = ({ author, children }) => {
  const [appInfo, setAppInfo] = useState(appData[author]);
  return (
    <AppContext.Provider value={{ appInfo, setAppInfo }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const { appInfo, setAppInfo } = useContext(AppContext);
  return { ...appInfo, setAppInfo };
};
export default useApp;
