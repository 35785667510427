import "@/styles/globals.css";
import { AppProvider } from "@/hooks/useApp";

const author = "rangyu";

export default function App({ Component, pageProps }) {
  return (
    <AppProvider author={author}>
      <Component {...pageProps} />
    </AppProvider>
  );
}
